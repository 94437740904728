import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Injectable()
export class ClientesService {
  constructor(private apiService: ApiService) { }

  save(params) {

    let token = localStorage.getItem('token_auth');
    if (params._id != null) {
      return this.apiService.put(`/cliente/${params._id}`, params,  {
        Authorization: 'Bearer '+ token
      });
    }
    return this.apiService.post('/cliente', params,  {
      Authorization: 'Bearer '+ token
    });
  }

  findCliente(id) {
    let token = localStorage.getItem('token_auth');
    return this.apiService.get(`/cliente/${id}`, {},  {
      Authorization: 'Bearer '+ token
    });
  }

  delete(id) {
    let token = localStorage.getItem('token_auth');
    return this.apiService.delete(`/cliente/${id}`, {},  {
      Authorization: 'Bearer '+ token
    });
  }

  retornaLista(params) {
    let token = localStorage.getItem('token_auth');
    return this.apiService.get('/cliente', {store_id: params},  {
      Authorization: 'Bearer '+ token
    });
  }
}
